<template>
  <div class="pt-6 px-4">
    <h1 class="text-xl mb-4 fot-bold text-gray-400">Sites</h1>
    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
      <input type="text" name="site" class="border-b-1 border-gray-100 w-full mb-4" placeholder="Input site name" v-model="siteName" />
      <ul>
        <li
         v-for="(site, si) in sites" :key="'si-' + si"
         class="border-b-1"
         >
          <div class="flex">
            <p>
              <router-link
               :to="'/sites/' + site.name + '/'"
               class="inline-block py-4"
               >
                <span class="font-semibold text-blue-500">{{site.name}}</span>
              </router-link>
            </p>
          </div>
        </li>
      </ul>
      <p class="mt-4 text-center text-sm">{{total}}件</p>
      <Pager :page="page" :totalPage="Math.ceil(total / perpage)" v-if="total" />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { db } from "../db";
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import Pager from '@/components/Blocks/Pager'

export default {
  name: 'Sites',
  setup () {
    const route = useRoute()
    const perpage = 10;
    let page = ref(0);
    let total = ref(0);
    let offset = ref(0);
    let sites = ref([]);
    let siteName = ref('');

    page.value = (route.query.page)? parseInt(route.query.page): 1;
    const setOffset = ()=> {
      offset.value = (page.value > 0)? parseInt((page.value - 1)) * perpage: 0;
    }

    const load = async () => {
      // list
      sites.value = await db.sites
      .where('name')
      .startsWith(siteName.value)
      .offset(offset.value)
      .limit(perpage)
      .toArray();

      // total
      total.value = await db.sites
      .where('name')
      .startsWith(siteName.value).count();
    }

    setOffset()
    load()

    watch(siteName, () => {
      load()
    })

    onBeforeRouteUpdate(async (to, from) => {
      if (to.query.page !== from.query.page) {
        page.value = to.query.page;
        setOffset()
        load()
      }
    })

    return {
      perpage,
      page,
      db,
      sites,
      siteName,
      total
    };
  },
  components: {
    Pager
  },
}
</script>
