<template>
  <nav aria-label="Page navigation" class="text-center">
    <ul class="inline-flex -space-x-px justify-center mt-4">
      <li v-if="page > 1">
        <router-link :to="{path: $route.path, query: {page: parseInt(page) - 1}}" class="cursor-pointer py-2 px-3 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</router-link>
      </li>
      <li v-for="n in pages" :key="'n-' + n">
        <router-link

         :to="{path: $route.path, query: {page: n }}" class="cursor-pointer py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        :class="{'font-bold bg-gray-400 text-white': n == page}"
        >{{n}}</router-link>
      </li>
      <li v-if="page < totalPage">
        <router-link :to="{path: $route.path, query: {page: parseInt(page) + 1}}" class="cursor-pointer py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'Pager',
  props: ['page', 'totalPage'],
  setup(props) {
    const pages = [];
    const maxPgBtn = 5;

    pages.push(props.page)
    if(props.totalPage < maxPgBtn){
      let count = 0;
      while(count <props.totalPage){
        count++;
        if(count !== props.page){
          pages.push(count);
        }
      }
    }else{
      if((props.page - 1) > 0){
        pages.push((props.page - 1))
      }
      if((props.page - 2) > 0){
        pages.push((props.page - 2))
      }
      if((props.page + 1) <= props.totalPage){
        pages.push((props.page + 1))
      }
      if((props.page + 2) <= props.totalPage){
        pages.push((props.page + 2))
      }
    }
    pages.sort(function(a, b) {
      return a - b;
    });

    return {
      pages
    }
  }
}
</script>
